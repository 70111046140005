.inputBackground {
  --padding-start: 15px;
  --padding-end: 10px;
  border-top: 1px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
  border-radius: 6px;
}

.titleStartPadding {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.noPadding {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
}

.inputInfo {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
}

.inputAlert {
  margin-left: 8px;
}

.spacer {
  --padding-start: 3px;
  --padding-end: 3px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
}

.iconSpacer {
  --padding-start: 4px;
  --padding-end: 4px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --background: transparent;
}

.textGrayLeftInfoText {
  font-family: OceanicGrotesk, serif !important;
  font-size: 14px !important;
  color: #666666 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
}

.textRedRightInfoText {
  font-family: OceanicGrotesk, serif !important;
  font-size: 12px !important;
  color: var(--ion-customer-theme) !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  margin: 0;
}

.textPasswordLength {
  opacity: 0.55 !important;
  font-family: OceanicGrotesk, serif !important;
  font-size: 12px !important;
  color: #666666 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  text-align: left !important;
  margin: 0;
}

.hiddenField {
  display: none;
}
