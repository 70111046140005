.calendarEventDetailsTitle {
  font-size: 18px !important;
  margin-top: 20px;
  margin-bottom: 25px;
}

.calendarEventDetailsBody {
  margin-top: 5px;
  margin-bottom: 5px;
}

.buttonAttend {
  margin: 40px auto 20px;
}
