.registerCheckBox {
  background: #ffffff;
  margin: 0 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 6px;
  border-color: #979797 !important;
  --size: 34px;
  --checkmark-color: var(--ion-customer-theme);
  --background-checked: #ffffff;
  --border-color: #ffffff;
  --border-color-checked: #ffffff;
}

.registerCheckBoxText {
  font-family: OceanicGrotesk, serif !important;
  font-size: 16px !important;
  color: #666666 !important;
  letter-spacing: 0 !important;
  margin: 0 !important;
  text-align: center;
  line-height: 34px !important;
  padding-left: 2px;
}
