.sideMenu {
  --min-width: 85%;
}

.sideMenuDivider {
  height: 1px;
  background: var(--ion-color-sidemenu-divider);
  margin-top: 15px;
  margin-bottom: 15px;
}

.sideMenuLogOut {
  margin-left: 35px;
  margin-right: 35px;
}

.sideMenuDesktop {
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
}

.sideMenuDesktopContent {
  --ion-item-background: white;
}

.sideMenuCard {
  background: white;
  margin: 16px 10px 0 50px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 2px 3px 0 #0000001f;
  height: calc(100vh - 96px);
}
