.feed {
  max-height: 100% !important;
  overflow: auto;
}

.feedList {
  padding-top: 10px;
  padding-bottom: 10px;
  --ion-item-background: var(--ion-color-lightGrey) !important;
}

.OmaUserCell {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  box-shadow: 0 2px 3px 0 #0000001f;
  --ion-item-background: white !important;
}

.OmaUserCell:hover {
  cursor: pointer;
  box-shadow: 0 4px 5px 0 #0000001f;
}

.feedCellTitleContainer {
  display: flex;
  flex-direction: row;
}

.feedCellAuthor {
  background: var(--ion-color-lightRed);
  padding: 3px 7px;
  border-radius: 4px;
  margin-left: 10px;
}

.feedCellCustomer {
  background: #d8d8d852;
  padding: 3px 7px;
  border-radius: 4px;
  margin-left: 10px;
}

.feedCellOtherTitle {
  opacity: 0.7;
  margin-left: 10px;
}

.feedCellImage {
  display: block;
  width: 100%;
  max-height: 300px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}

.feedCellLike {
  width: 48px;
  height: 48px;
  padding: 14px;
}

.feedNoticeCell {
  align-items: center;
  background: var(--ion-color-grey);
  border-radius: 6px;
  box-shadow: 0 2px 3px 0 #0000001f;
  margin-top: 6px;
  padding: 16px 20px;
  cursor: pointer;
}

.feedOnboardingCell {
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 6px;
  border-color: #97979730 !important;
  margin-top: 10px;
}

.feedOnboardingCell:hover {
  cursor: pointer;
}

.feedOnboardingCellCircle {
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
  background: #fae8e7;
  margin: 10px;
}

.feedOnboardingCellTextContainer {
  padding: 10px 0;
  margin-top: auto;
  margin-bottom: auto;
}

.feedOnboardingCellCloseImage {
  padding: 10px;
}

.feedOnboardingCellCloseImage:hover {
  opacity: 0.5;
}

.AdminFormContainer {
  --ion-item-background: white;
}

.AdminFormContainer ion-item {
  --highlight-height: 0;
}

.invitationStatus {
  display: flex !important;
  align-items: center;
  font-weight: normal;
}

.invitationStatus span {
  font-size: small;
  font-weight: bold;
  margin-left: 16px;
}