.errorSmallView {
  margin: 20px 40px;
}

.errorSmallImage {
  width: 44px;
  height: 44px;
}

.errorSmallTitle {
  margin-top: 12px;
  margin-bottom: 20px;
}

.errorSmallButton {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}
