.centerBigImage {
  text-align: center;
  height: 64px;
}

.centerSmallImage {
  text-align: center;
  height: 32px;
}

.bigHeaderTopSpacer {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  height: 50px;
}

.bigHeaderBottomSpacer {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  height: 30px;
}

.withoutNavigationTopSpacer {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  height: 50px;
}

.withoutNavigationTitle {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  text-align: center;
  height: 28px;
}

.withoutNavigationBottomSpacer {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  height: 60px;
}

.appTitleHeader {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 56px;
}

.appTitleIconNormal {
  width: 20px;
  height: 20px;
  color: var(--ion-customer-theme)
}
