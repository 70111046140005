.codeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.codeContainer .code {
  margin: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.codeContainer .innstructions {
  margin: 20px;
  margin-top: 0;
  text-align: center;
}
