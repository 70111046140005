.profilePageSpan {
  color: var(--ion-customer-theme);
}

.profilePageSpan:hover {
  cursor: pointer;
  opacity: 0.5;
}

.space {
  height: 12px;
}

.profilePageRow {
  height: 65px;
}

.profilePageChevron {
  margin-right: 8px;
}

.profilePageToggle {
  margin-right: 2px;
  --background: var(--ion-color-input-placeholder);
  --background-checked: var(--ion-customer-theme);
}
