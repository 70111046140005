.pickerModal {
  position: absolute;
  margin: auto;
  padding: 20px;
  --border-color: silver;
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 10px;
}

.registerCheckBox {
  height: 24px;
  width: 24px;
}

.pickerSpinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}
