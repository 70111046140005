.onboardingRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#slides {
  height: 100%;
}

.onboardingSlide {
  height: calc(100% - 60px);
  background: transparent;
}

.onboardingBgImage {
  height: calc(100% - 140px);
  width: 90%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.onBoardingInfoText {
  font-family: RalewaySemiBold, serif !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  color: var(--ion-color-grey) !important;
  --ion-item-background: transparent;
}

.onBoardBoldRedText {
  font-weight: bold;
  color: var(--ion-customer-theme);
}

.onboardingFooter {
  background-color: var(--ion-color-lightGrey);
  --ion-border-color: transparent;
  padding-bottom: 30px;
}

.onBoardingInfoText ion-label {
  text-align: start;
}

.onBoardingInfoText li {
  text-align: start;
}
