:root {
  //--ion-customer-theme: #00AEEF;
  --ion-customer-theme: #58AF59;
  

  --ion-color-red: #ee1b25;
  --ion-color-lightRed: #ffd9da;
  --ion-color-green: #00c957;
  --ion-color-blue: #0076ff;
  --ion-color-charCoal: #222222;
  --ion-color-grey: #666666;
  --ion-color-lightGrey: #f9f9f9;
  --ion-color-divider: #e9e9ea;
  --ion-color-sidemenu-divider: #ddddde;
  --ion-color-input-placeholder: #666666aa;
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red) !important;
}

.ion-color-lightRed {
  --ion-color-base: var(--ion-color-lightRed) !important;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green) !important;
}

.--ion-color-blue {
  --ion-color-base: var(--ion-color-blue) !important;
}

.ion-color-charCoal {
  --ion-color-base: var(--ion-color-charCoal) !important;
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey) !important;
}

.ion-color-lightGrey {
  --ion-color-base: var(--ion-color-lightGrey) !important;
}

.ion-color-divider {
  --ion-color-base: var(--ion-color-divider) !important;
}
