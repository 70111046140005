.mainTabs {
  width: 100%;
  height: 60px;
  display: flex !important;
  flex-direction: column !important;
}

.mainTabsRow {
  width: 100%;
  align-items: center;
  background-color: white;
  display: flex;
}

.mainTabsButton {
  flex-grow: 1;
  height: 60px;
}

.verticalDivider {
  width: 1px;
  flex-grow: 0;
  min-height: 60px;
  padding: 0;
  background: var(--ion-color-divider);
}

.divider {
  height: 1px;
  background: var(--ion-color-divider);
  --ion-item-background: var(--ion-color-divider);
}
