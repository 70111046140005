
.flImageContainer {
    position: relative;
    text-align: center;
    display: flex;
    padding: 3px;
}

.flImageContainer label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    border: 3px solid linen;
}

.flImageContainer .previewImageBox {
    padding: 10px;
    width: 100%;
    display: block;
    position: relative;
}



.flImageContainer div {
    position: absolute;
    top: -3px;
    right: -3px;
    display: none;
}

.flImageContainer:hover div {
    display: block;
}


.flImageReleaseMask{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.815);
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flImageReleaseMask span {
    color: white;
}


.cvEditorView .cvContent .positions .position .edits {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.cvEditorView .cvContent .positions .position:hover .edits {
    display: block;
}
