.emptyView {
  margin: 60px;
}

.emptyImage {
  max-width: 300px;
}

.emptyTitle {
  margin-top: 40px;
  line-height: 24px;
  opacity: 0.57;
}
