.searchMenu {
  width: 50%;
  text-align: right;
}

.searchMenu .bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.searchMenu .bottomRow .activeSelector {
  margin-left: 10px;
  padding-top: 4px;
}

.searchMenu .activeSelector .activeSelectorCb {
  --background: transparent;
  --background-checked: transparent;
  --checkmark-color: black;
  --border-color-checked: #c9c9c9;
  --border-color: #c9c9c9;
  --border-width: 1px;
  --size: 12px;
  margin-left: 4px;
  margin-right: 2px;
  top: 1px;
  position: relative;
}

.searchMenu .activeHomePicker {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.searchMenu input {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.searchMenu .searchInput {
  padding-right: 24px !important;
  margin-bottom: 5px;
  border: 1px solid silver;
  border-radius: 6px;
  margin-bottom: 8px !important;
  flex-grow: 0.8;
}

.searchMenu .searchIcon {
  position: absolute;
  right: 4px;
  top: 6px;
}
