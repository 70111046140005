.activeHomePickerInterface {
  --width: 600px;
  --max-width: 80%;
}

.activeHomePickerInterface .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-text-color, #000);
}

.activeHomePickerInterface .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-text-color, #000);
}

.customerNewsHomePicker {
  margin-right: 20px;
}