.errorView {
  margin: 40px;
}

.errorTitle {
  margin-top: 40px;
}

.errorBody {
  margin-top: 20px;
}

.errorButton {
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}
