/* TEXT */
.pageHeaderTitle {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 19px;
}

.heading1 {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: light;
}

.heading1SemiBold {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
}

.heading2SemiBold {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
}

.title {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 23px !important;
  font-style: normal;
  line-height: 24px;
}

.body1 {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  font-style: normal;
  line-height: 19px;
}

.body1Bold {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 19px;
}

.body2 {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 14px !important;
  font-style: normal;
  line-height: 19px;
}

.body2Bold {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 19px;
}

.body3 {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 12px !important;
  font-style: normal;
  line-height: 19px;
}

.body3Bold {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 12px !important;
  font-weight: bold;
  line-height: 19px;
}

.smallText {
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 11px !important;
  font-style: normal;
  line-height: 19px;
}

/* BUTTON */
.buttonRed {
  min-height: 48px;
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-weight: 500;
  font-size: 16px !important;
  --background: var(--ion-customer-theme);
  --color: white;
  --border-radius: 24px;
}

.buttonAddNew {
  max-height: 30px;
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 14px !important;
  --background: var(--ion-customer-theme);
  --color: white;
  --border-radius: 4px;
  margin: 5px 5px 10px 5px;
  font-weight: 500;
}

.buttonAddNew:hover {
  opacity: 0.8;
}

.buttonGreen {
  min-height: 48px;
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  --background: var(--ion-color-green);
  --color: white;
  --border-radius: 24px;
  font-weight: 500;
}

.buttonRedOutline {
  min-height: 48px;
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 16px !important;
  --background: transparent;
  --color: var(--ion-customer-theme);
  --ion-color-primary-shade: var(--ion-color-lightRed);
  border: 1px var(--ion-customer-theme) solid;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  --border-radius: 24px;
  font-weight: 500;
}

.buttonRedOutline:hover {
  opacity: 0.8;
}

.buttonGreyOutlineSmall {
  min-height: 36px;
  --ion-font-family: OceanicGrotesk;
  font-family: OceanicGrotesk, serif;
  font-size: 14px !important;
  --background: transparent;
  --color: var(--ion-customer-theme);
  --ion-color-primary-shade: var(--ion-color-lightRed);
  border: 1px #66666640 solid;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  --border-radius: 24px;
  --padding-start: 30px;
  --padding-end: 30px;
  font-weight: 600;
}

.buttonGreyOutlineSmall:hover {
  opacity: 0.8;
}

/* ALIGNMENT */
.centered {
  text-align: center;
  margin: auto;
}

/* COLOR */
.theme {
  color: var(--ion-customer-theme) !important;
  --color: var(--ion-customer-theme) !important;
}

.red {
  color: var(--ion-color-red) !important;
  --color: var(--ion-color-red) !important;
}

.blue {
  color: var(--ion-color-blue) !important;
  --color: var(--ion-color-blue) !important;
}

.charCoal {
  color: var(--ion-color-charCoal) !important;
  --color: var(--ion-color-charCoal) !important;
}

.grey {
  color: var(--ion-color-grey) !important;
  --color: var(--ion-color-grey) !important;
}

.white {
  color: white !important;
  --color: white !important;
}

.pageWhite {
  --ion-background-color: white !important;
  --ion-item-background: white !important;
  --ion-toolbar-background: white !important;
}

.pageLightGrey {
  --ion-background-color: var(--ion-color-lightGrey) !important;
  --ion-item-background: var(--ion-color-lightGrey) !important;
  --ion-toolbar-background: white !important;
}

.contentLightGrey {
  --ion-background-color: var(--ion-color-lightGrey);
}

.opacity50 {
  opacity: 0.5;
}

/* PADDING */
.contentPaddingHorizontal {
  --padding-start: 40px;
  --padding-end: 40px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.noPadding {
  --min-height: 0px;
}

/* MARGIN */
.marginTop10px {
  margin-top: 10px;
}

.marginTop15px {
  margin-top: 15px;
}

.marginTop20px {
  margin-top: 20px;
}

.marginTop30px {
  margin-top: 30px;
}

/* OTHER */
.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.5 !important;
}

.pointer {
  cursor: pointer;
}

.tabSelected {
  color: var(--ion-customer-theme);
  letter-spacing: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--ion-customer-theme);
}

.tabNotSelected {
  color: var(--ion-color-grey);
  letter-spacing: 0;
}

.pageHeader {
  --min-height: 60px;
  --background: white;
}

.pageHeaderDivider {
  height: 1px;
  background: var(--ion-color-divider);
}

.pageDivider {
  height: 1px;
  background: var(--ion-color-sidemenu-divider);
}

.capitalizeFirstLetter {
  display: block;
}

.capitalizeFirstLetter:first-letter {
  text-transform: uppercase;
}

.pageWidth {
  max-width: 576px;
}

.contentMaxWidth {
  max-width: 768px;
}

.unreadBadge {
  min-width: 8px !important;
  max-width: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
  background: var(--ion-customer-theme);
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  --border-radius: 4px !important;
  position: absolute;
  top: 4px;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

ion-select {
  --placeholder-opacity: 1;
}

.alert-radio-label.sc-ion-alert-md {
  color: var(--ion-text-color, #000) !important;
}

/*
 * This adds a scroll bar to ionic alerts
 */
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.7em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
