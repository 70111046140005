.passwordRecovery {
  --background: var(--ion-color-lightGrey);
  border-radius: 6px;
  --inner-padding-top: 16px;
  --inner-padding-right: 16px;
  --inner-padding-bottom: 16px;
  --inner-padding-left: 16px;
  margin-top: 30px;
  margin-bottom: 10px;
}
