.loadingCustomCss {
  --background: #fcf8f7;
  --spinner-color: gray;

  color: black;
}

.spinnerSmall {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  z-index: 1000;
}

.spinnerDefault {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 1000;
}

.spinnerLarge {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 1000;
}
