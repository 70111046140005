.registerLabelTitleText {
  opacity: 0.5 !important;
  letter-spacing: 0 !important;
  margin-top: 20px;
  margin-bottom: 0;
  margin-left: 0;
}

.registerLabelText {
  letter-spacing: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.registerAdditionalText {
  letter-spacing: 0 !important;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 0;
}

.phoneNumber {
  font-size: 18px !important;
}

.buttonBottom {
  width: 100%;
  margin: 48px;
}

.buttonBottomConsent {
  width: 100%;
  margin: 24px 48px 48px;
}
