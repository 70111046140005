
.customerNewsEditor {
  margin-left: 10px;
  margin-right: 10px;
}


.customerNewsEditor .headerItem {
  --ion-item-background: white;
  border: 1px solid var(--ion-color-divider);
  border-radius: 4px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.customerNewsEditor .titleInput input {
  padding: 15px;
  font-size: 1.5em;
}

.customerNewsEditor .leadItem {
  border: 1px solid var(--ion-color-divider);
  border-radius: 4px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.customerNewsEditor .leadInput input {
  padding: 15px;
}

.customerNewsEditor .photoUploadItem {
  margin-bottom: 15px;
  margin: auto;
}

.customerNewsEditor .photoUploadItem .headerPhoto{
  margin: auto;
}

.customerNewsEditor .photoUploadItem img {
  max-height: 40vh;
}


.customerNewsEditor .contentEditorItem {
  margin-bottom: 15px;
  --ion-item-background: white;
  background-color: white;
  border: 1px solid var(--ion-color-divider);
  border-radius: 4px;
  margin-top: 5px;
}

.customerNewsEditor .contentEditorItem .toolbarClass {
  border: none;
}

.customerNewsEditor .contentPreview {
  margin-bottom: 15px;
  --ion-item-background: white;
  width: 100%;
  border: 1px solid var(--ion-color-divider);
  border-radius: 4px;
  margin-top: 5px;

}

.customerNewsEditor .fieldLabel {
  font-size: 0.7em;
}

.customerNewsModal {
    --min-width: 900px;
}